@import "~antd/dist/antd.less";

.login-container {
  align-items: center;
  display: flex;
  height: 100vh;
  background-color: #f5f5f5;
  justify-content: center;
  flex-direction: row;
}

.ant-form.login-form {
  border: 1px #c4c4c4 solid;
  border-radius: 5px;
  background-color: #ffffff;
  justify-content: flex-start;
  margin: 50px;
}

@media (min-width: 640px) {
  .ant-form.login-form {
    width: 28rem;
    padding: 2rem;
  }
}

@media (max-width: 1024px) {
  #imageSection {
    display: none;
  }
  .ant-form.login-form {
    padding: 2rem;
    border: 1px black solid;
  }
  .login-container {
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), rgba(178, 174, 174, 0.8)), url("https://static.inmatics.io/backgrounds/parking.jpg");
    background-size: cover;
    background-position: center;
  }
}

@media (max-width: 640px) {
  #imageSection {
    display: none;
  }
  .ant-form.login-form {
    margin: 0;
    padding: 2rem;
    border: 1px black solid;
  }
  .login-container {
    background-size: cover;
    background: #f5f5f5 center;
  }
}

@media (min-width: 768px) {
  .ant-form.login-form {
    width: 28rem;
    padding: 2rem;
  }
}

.login-form-buttons {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}
@primary-color: #33669a;@layout-header-background: #33669a;